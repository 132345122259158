

@media (max-width: 767px) {
    h1 {
      font-size: 27px;
    }
    .Printall-label{
      font-size: 10px;
    }
    
  }
  
  @media (max-width: 767px) {
    h2 {
      font-size: 23px;
    }
  }
  
  @media (max-width: 1023px) {
    h3 {
      font-size: 20px;
    }
  }
  @media (max-width: 767px) {
    h3 {
      font-size: 16px;
    }
  }
  
  @media (max-width: 1023px) {
    h4 {
      font-size: 18px;
    }
  }
  @media (max-width: 767px) {
    h4 {
      font-size: 18px;
    }
  }
  
  @media (max-width: 1023px) {
    h5 {
      font-size: 18px;
    }
  }
  
  @media (max-width: 1023px) {
    h6 {
      font-size: 16px;
    }
  }
  
@media (min-width: 320px) {
    .col.col-xs-12 {
      width: 100%;
    }
    .col.col-xs-11 {
      width: 91.66666667%;
    }
    .col.col-xs-10 {
      width: 83.33333333%;
    }
    .col.col-xs-9 {
      width: 75%;
    }
    .col.col-xs-8 {
      width: 66.66666667%;
    }
    .col.col-xs-7 {
      width: 58.33333333%;
    }
    .col.col-xs-6 {
      width: 50%;
    }
    .col.col-xs-5 {
      width: 41.66666667%;
    }
    .col.col-xs-4 {
      width: 33.33333333%;
    }
    .col.col-xs-3 {
      width: 25%;
    }
    .col.col-xs-2 {
      width: 16.66666667%;
    }
    .col.col-xs-1 {
      width: 8.33333333%;
    }
  }
  @media (min-width: 568px) {
    .col.col-sm-12 {
      width: 100%;
    }
    .col.col-sm-11 {
      width: 91.66666667%;
    }
    .col.col-sm-10 {
      width: 83.33333333%;
    }
    .col.col-sm-9 {
      width: 75%;
    }
    .col.col-sm-8 {
      width: 66.66666667%;
    }
    .col.col-sm-7 {
      width: 58.33333333%;
    }
    .col.col-sm-6 {
      width: 50%;
    }
    .col.col-sm-5 {
      width: 41.66666667%;
    }
    .col.col-sm-4 {
      width: 33.33333333%;
    }
    .col.col-sm-3 {
      width: 25%;
    }
    .col.col-sm-2 {
      width: 16.66666667%;
    }
    .col.col-sm-1 {
      width: 8.33333333%;
    }
  }
  @media (min-width: 768px) {
    .col.col-md-12 {
      width: 100%;
    }
    .col.col-md-11 {
      width: 91.66666667%;
    }
    .col.col-md-10 {
      width: 83.33333333%;
    }
    .col.col-md-9 {
      width: 75%;
    }
    .col.col-md-8 {
      width: 66.66666667%;
    }
    .col.col-md-7 {
      width: 58.33333333%;
    }
    .col.col-md-6 {
      width: 50%;
    }
    .col.col-md-5 {
      width: 41.66666667%;
    }
    .col.col-md-4 {
      width: 33.33333333%;
    }
    .col.col-md-3 {
      width: 25%;
    }
    .col.col-md-2 {
      width: 16.66666667%;
    }
    .col.col-md-1 {
      width: 8.33333333%;
    }
  }
  @media (min-width: 1024px) {
    .col.col-lg-12 {
      width: 100%;
    }
    .col.col-lg-11 {
      width: 91.66666667%;
    }
    .col.col-lg-10 {
      width: 83.33333333%;
    }
    .col.col-lg-9 {
      width: 75%;
    }
    .col.col-lg-8 {
      width: 66.66666667%;
    }
    .col.col-lg-7 {
      width: 58.33333333%;
    }
    .col.col-lg-6 {
      width: 50%;
    }
    .col.col-lg-5 {
      width: 41.66666667%;
    }
    .col.col-lg-4 {
      width: 33.33333333%;
    }
    .col.col-lg-3 {
      width: 25%;
    }
    .col.col-lg-2 {
      width: 16.66666667%;
    }
    .col.col-lg-1 {
      width: 8.33333333%;
    }
  }
  @media (max-width: 991.98px) {
    .btn-submit {
      min-width: auto;
      padding: 5px 7px;
    }
  }
  @media (max-width: 991.98px) {
    .btn-cancel {
      min-width: auto;
      padding: 5px 7px;
    }
  }
  @media (max-width: 991.98px) {
    .page-wrapper {
      margin: 0;
      padding: 60px 0 0;
    }
  }
  @media (max-width: 991.98px) {
    .page-wrapper .content {
      padding: 5px;
    }
  }
  @media (max-width: 991.98px) {
    .sidebarrightmenu .page-wrapper {
      margin: 0;
    }
  }
  @media (max-width: 991.98px) {
    .card .card-header .card-title {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    .card .card-body {
      padding: 15px;
    }
  }
  @media (max-width: 991.98px) {
    .card .card-body .card-title {
      font-size: 14px;
    }
  }
  @media (max-width: 991.98px) {
    .form-group .input-groupicon .addonset {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .table-height {
      min-height: auto;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 991.98px) {
    .login-wrapper .login-content {
      width: 100%;
    }
  }
  @media (max-width: 991.98px) {
    .login-wrapper .login-content .login-userset {
      margin-top: 30px;
    }
  }
  @media (max-width: 991.98px) {
    .login-wrapper .login-content .form-setlogin h4:after {
      height: 0px;
    }
  }
  @media (max-width: 991.98px) {
    .login-wrapper .login-content .form-setlogin h4:before {
      height: 0px;
    }
  }
  @media (max-width: 575px) {
    .login-wrapper .login-content .form-sociallink ul li {
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    .login-wrapper .login-content .form-sociallink ul li:first-child {
      padding-right: 0;
    }
  }
  @media (max-width: 991.98px) {
    .login-wrapper .login-img {
      display: none;
    }
  }
  @media (max-width: 1199.98px) {
    .sidebar.new-header .sidebar-menu > ul > li {
      margin-right: 0;
    }
  }
  
  @media (min-width: 992px) {
    .hide-sidebar {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .sidebar.sidebar-two {
      left: 0;
      top: 60px;
    }
  }
  @media (max-width: 1199.98px) {
    .sidebar.side-three.new-header .sidebar-menu > ul > li {
      margin-right: 0;
    }
  }
  @media (min-width: 992px) {
    .hide-sidebar {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .sidebar {
      margin-left: -575px;
      -webkit-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      transition: all 0.4s ease;
      z-index: 1041;
      background: #fff;
    }
  }
  @media (max-width: 575px) {
    .sidebar {
      width: 100%;
    }
  }
  @media (max-width: 575px) {
    .sidebar .slimScrollDiv {
      width: 100% !important;
    }
  }
  @media (max-width: 991.98px) {
    .sidebar .sidebar-right {
      width: calc(100% - 70px);
    }
  }
  @media (max-width: 991.98px) {
    .sidebar .sidebar-right .slimScrollDiv {
      width: 100% !important;
    }
  }
  @media (max-width: 991.98px) {
    .sidebar .sidebar-right .tab-content {
      padding: 10px;
    }
  }
  @media (min-width: 991.98px) {
    .mini-sidebar .page-wrapper {
      margin-left: 80px;
    }
    .mini-sidebar .header-left #toggle_btn {
      /* opacity: 0; */
    }
    .mini-sidebar .header-left #toggle_btn:after {
      /* border: 0; */
    }
    .mini-sidebar.expand-menu .header-left #toggle_btn {
      opacity: 1;
    }
    .mini-sidebar .sidebar-right {
      display: none;
    }
    .mini-sidebar .sidebar .sidebar-menu ul li a span {
      display: none;
    }
    .mini-sidebar .sidebar .sidebar-menu ul li .submenu-hdr {
      display: none;
    }
    .mini-sidebar .sidebar .sidebar-menu ul li svg {
      margin-right: 0;
    }
    .mini-sidebar .sidebar {
      width: 80px;
      overflow: hidden;
    }
    .expand-menu .sidebar-right {
      display: block;
      -webkit-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    .expand-menu .sidebar {
      width: 260px;
    }
    .expand-menu .sidebar .sidebar-menu ul > li > a {
      padding: 10px 15px;
    }
    .expand-menu .sidebar .sidebar-menu ul > li > a span {
      display: inline-block;
      line-height: 0;
    }
    .expand-menu .sidebar .sidebar-menu ul > li svg {
      margin-right: 10px;
    }
    .expand-menu .sidebar .sidebar-menu .submenu-hdr {
      display: block;
    }
    .expand-menu .header-left {
      width: 260px;
    }
    #toggle_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      float: left;
      margin-left: 15px;
    }
  }
  @media (max-width: 991.98px) {
    .sidebar-content .sidebar-header h5 {
      font-size: 18px;
    }
  }
  @media (min-width: 992px) {
    .mini-sidebar.expand-menu .header-left {
      flex-direction: row;
      justify-content: space-between;
    }
    .mini-sidebar .header-left {
      width: 80px;
      padding: 0;
      display: flex;
      /* align-items: center;
      justify-content: center;
      flex-direction: column; */
    }
    .mini-sidebar .header-left .logo-small {
      display: block;
      padding: 0px 0 0 4px;
    }
    .mini-sidebar .header-left .logo {
      display: none;
    }
    .mini-sidebar .menu-arrow {
      display: none !important;
    }
    .mini-sidebar .chat-user {
      display: none !important;
    }
    .mini-sidebar .badge {
      display: none !important;
    }
    .mini-sidebar .user-menu .badge {
      display: flex !important;
    }
    .mini-sidebar.expand-menu .menu-arrow {
      display: block !important;
    }
    .mini-sidebar.expand-menu .chat-user {
      display: block !important;
    }
    .mini-sidebar.expand-menu .logo-small {
      display: none;
    }
    .mini-sidebar.expand-menu .logo {
      display: block;
    }
    .mini-sidebar.expand-menu #toggle_btn {
      opacity: 1;
    }
    .mini-sidebar.expand-menu .header-left {
      padding: 0 20px;
      display: flex;
    }
    .mini-sidebar .noti-dot:before {
      display: none;
    }
  }
  @media (max-width: 991.98px) {
    .timeline:before {
      left: 40px;
    }
  }
  @media (max-width: 991.98px) {
    .timeline li .timeline-badge {
      top: 16px;
      left: 15px;
      margin-left: 0;
    }
  }
  @media (max-width: 991.98px) {
    .timeline li .timeline-panel {
      float: right;
      width: calc(100% - 90px);
    }
    .cdk-overlay-pane{
      width: 95vw !important;
      max-width: 95vw !important;
    }
  }
  