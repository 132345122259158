
body[data-theme=dark] {
    background-color: #141432;
    color: #97A2D2;
  }
  body[data-theme=dark] .header {
    background: #141432;
    border-bottom: 1px solid #434548;
  }
  body[data-theme=dark] .header .header-left {
    border-right: 1px solid #637381;
    border-bottom: 1px solid #434548;
  }
  body[data-theme=dark] .header .header-left .logo-normal {
    display: none;
  }
  body[data-theme=dark] .header .header-left .logo-white {
    display: block !important;
  }
  body[data-theme=dark] .sidebar {
    border-right: 1px solid #637381;
  }
  body[data-theme=dark] .sidebar .slimScrollDiv {
    background: #141432;
  }
  body[data-theme=dark] .sidebar.sidebar-four {
    border: 0;
  }
  body[data-theme=dark] .page-wrapper {
    background: #141432;
  }
  body[data-theme=dark] .page-wrapper.page-wrapper-three .content {
    background: #141432;
  }
  body[data-theme=dark] #toggle_btn:before {
    border: 2px solid #97A2D2;
  }
  body[data-theme=dark] .user-info .user-name {
    color: #97A2D2;
  }
  body[data-theme=dark] .user-menu .nav-item-box > a {
    background: #2c2c50;
  }
  body[data-theme=dark] .header-left.active #toggle_btn:after {
    border: 2px solid #97A2D2;
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li.submenu ul {
    background: #141432;
    border-radius: 5px;
    padding: 10px 0;
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li.submenu ul li a {
    color: #8ea0af;
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li a img {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li a span {
    color: #8ea0af;
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li a:hover img {
    color: #8ea0af;
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li a:hover svg {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .sidebar .sidebar-menu > ul > li .submenu-hdr {
    color: #8ea0af;
  }
  body[data-theme=dark] .dash-widget {
    background: #1d1d42;
    border: 1px solid #353570;
  }
  body[data-theme=dark] .dash-widget .dash-widgetcontent h5 {
    color: #97A2D2;
  }
  body[data-theme=dark] .dash-widget .dash-widgetcontent h6 {
    color: #97A2D2;
  }
  body[data-theme=dark] .page-header h4, body[data-theme=dark] .page-header h6 {
    color: #97A2D2;
  }
  body[data-theme=dark] .form-control {
    color: #97A2D2;
    border: 1px solid rgba(145, 158, 171, 0.32);
  }
  body[data-theme=dark] select.form-control:focus {
    color: #97A2D2;
  }
  body[data-theme=dark] .form-group label {
    color: #97A2D2;
  }
  body[data-theme=dark] .dataTables_info {
    color: #97A2D2;
  }
  body[data-theme=dark] .setvaluecash ul li a {
    color: #97A2D2;
  }
  body[data-theme=dark] .inbox-menu li a i {
    color: #97A2D2;
  }
  body[data-theme=dark] .card {
    background-color: #1d1d42;
    border: 1px solid #353570;
  }
  body[data-theme=dark] .card .card-header .card-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .card .card-body .card-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .modal-content {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .modal-content .modal-header {
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .inputcheck {
    color: #97A2D2;
  }
  body[data-theme=dark] .fc th.fc-widget-header {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .fc-day {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .chat-cont-left .chat-scroll .media .media-body .user-name, body[data-theme=dark] .chat-cont-left .chat-scroll .media .media-body .user-last-chat {
    color: #97A2D2;
  }
  body[data-theme=dark] .chat-cont-left .chat-scroll .media.active, body[data-theme=dark] .chat-cont-left .chat-scroll .media:hover {
    background: #1B2850;
  }
  body[data-theme=dark] .chat-cont-right ul.list-unstyled .media.sent .media-body .msg-box p {
    color: #212B36;
  }
  body[data-theme=dark] .inbox-menu li a {
    color: #97A2D2;
  }
  body[data-theme=dark] .increment-decrement .quantity-field {
    color: #97A2D2;
  }
  body[data-theme=dark] .select2-results__options {
    color: #333;
  }
  body[data-theme=dark] .image-upload .image-uploads h4 {
    color: #97A2D2;
  }
  body[data-theme=dark] .new-header {
    background: #141432;
    border-top: 1px solid #353570;
  }
  body[data-theme=dark] .new-header.side-three {
    border-color: #353570;
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li > a span {
    color: #8ea0af;
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li > a img, body[data-theme=dark] .new-header .sidebar-menu > ul > li > a svg {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue- rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li.submenu ul {
    background: #141432;
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li.submenu ul li a {
    color: #8ea0af;
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li.submenu ul li img, body[data-theme=dark] .new-header .sidebar-menu > ul > li.submenu ul li svg {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%) !important;
  }
  body[data-theme=dark] .new-header .sidebar-menu > ul > li.submenu ul li > ul {
    box-shadow: none;
  }
  body[data-theme=dark] .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a svg {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
    color: #8ea0af;
  }
  body[data-theme=dark] .table thead {
    background: #141432;
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .table thead tr:hover {
    background: transparent;
  }
  body[data-theme=dark] .table thead tr th {
    color: #97A2D2;
  }
  body[data-theme=dark] .table tbody tr:hover {
    background: transparent;
  }
  body[data-theme=dark] .table tbody tr td {
    color: #97A2D2;
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .table tbody tr td a {
    color: #97A2D2 !important;
  }
  body[data-theme=dark] .sidebar.sidebar-four .ui-aside .nav-item .nav-link {
    border-bottom: #353570;
  }
  body[data-theme=dark] .sidebar.sidebar-four .sidebar-menu ul li a img {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .select2-results__option {
    background: #1d1d42;
    color: #97A2D2;
  }
  body[data-theme=dark] .custom-file-container label {
    color: #97A2D2;
  }
  body[data-theme=dark] .select2-dropdown {
    border: 2px solid #26264e;
  }
  body[data-theme=dark] .swal2-popup {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .swal2-popup .swal2-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .swal2-popup .swal2-html-container {
    color: #97A2D2;
  }
  body[data-theme=dark] .searchinputs input {
    color: #97A2D2;
    background: #1d1d42;
  }
  body[data-theme=dark] .searchinputs input::placeholder {
    color: #8ea0af;
  }
  body[data-theme=dark] .image-upload-new .productviewscontent .productviewsname h2, body[data-theme=dark] .image-upload-new .productviewscontent .productviewsname h3 {
    color: #8ea0af;
  }
  body[data-theme=dark] .split-card::before, body[data-theme=dark] .split-card::after {
    background: #353570;
  }
  body[data-theme=dark] input[type=email] {
    border: 1px solid rgba(145, 158, 171, 0.32);
  }
  body[data-theme=dark] .tabs-set .nav-tabs .nav-link {
    background: #141432;
    color: #97A2D2;
  }
  body[data-theme=dark] .tabs-set .nav-tabs .nav-link.active {
    background: var(--background-primary-color);
    color: #fff;
  }
  body[data-theme=dark] .bor-b1 {
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .total-order ul li {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .total-order ul li:first-child {
    border-top: 1px solid #353570;
  }
  body[data-theme=dark] .total-order ul li h4 {
    border-right: 1px solid #353570;
  }
  body[data-theme=dark] hr {
    background-color: #353570;
  }
  body[data-theme=dark] .inputcheck .checkmark {
    background: transparent;
  }
  body[data-theme=dark] .productset {
    background-color: #1d1d42;
    border: 1px solid #353570;
  }
  body[data-theme=dark] .productset .productsetcontent h4 {
    color: #8ea0af;
  }
  body[data-theme=dark] .graph-sets .btn-white {
    background-color: #141432;
    color: #8ea0af;
  }
  body[data-theme=dark] .graph-sets .btn-white:hover {
    color: #8ea0af;
  }
  body[data-theme=dark] .graph-sets .dropdown-menu {
    background-color: #141432;
  }
  body[data-theme=dark] .graph-sets .dropdown-menu .dropdown-item {
    color: #8ea0af;
  }
  body[data-theme=dark] .graph-sets .dropdown-menu .dropdown-item:hover {
    color: #8ea0af;
    background: #1d1d42;
  }
  body[data-theme=dark] .header .dropdown-menu {
    background: #141432;
    color: #97A2D2;
  }
  body[data-theme=dark] .order-list .orderid h4, body[data-theme=dark] .order-list .orderid h5 {
    color: #97A2D2;
  }
  body[data-theme=dark] .productimg .productcontet h4 {
    color: #97A2D2;
  }
  body[data-theme=dark] .topnav-dropdown-header .notification-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .topnav-dropdown-footer a {
    color: #97A2D2;
  }
  body[data-theme=dark] .custom-file-container .custom-file-container__custom-file__custom-file-control__button {
    color: #97A2D2;
  }
  body[data-theme=dark] .notifications .notification-list li {
    border-bottom: 1px solid #8ea0af;
  }
  body[data-theme=dark] .notifications .notification-list li .noti-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .notifications .notification-list li .noti-details {
    color: #8ea0af;
  }
  body[data-theme=dark] .notifications .notification-list li .notification-time {
    color: #8ea0af;
  }
  body[data-theme=dark] .user-menu.nav li.flag-nav .dropdown-menu .dropdown-item {
    color: #8ea0af;
  }
  body[data-theme=dark] .user-menu.nav li.flag-nav .dropdown-menu .dropdown-item:hover {
    color: #8ea0af;
    background: #1d1d42;
  }
  body[data-theme=dark] .user-menu.nav.nav > li > a.dropdown-toggle.nav-link.show {
    background: #1d1d42;
  }
  body[data-theme=dark] .profilename .profileset h5, body[data-theme=dark] .profilename .profileset h6 {
    color: #8ea0af;
  }
  body[data-theme=dark] .header .menu-drop-user .dropdown-item {
    color: #8ea0af;
  }
  body[data-theme=dark] .header .menu-drop-user .dropdown-item:hover {
    color: #8ea0af;
    background: #1d1d42;
  }
  body[data-theme=dark] .card.bg-white {
    background-color: #1d1d42 !important;
  }
  body[data-theme=dark] .card .card-header {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .card .card-header .dropset {
    color: #8ea0af;
  }
  body[data-theme=dark] .card .card-footer {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .custom-file-container .custom-file-container__custom-file__custom-file-control {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .custom-file-container .custom-file-container__custom-file__custom-file-control .custom-file-container__custom-file__custom-file-control__button {
    border-left: 1px solid #353570;
  }
  body[data-theme=dark] .scanner-set img {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
    width: 16px;
  }
  body[data-theme=dark] .dropdown-menu {
    background: #141432;
    color: #97A2D2;
  }
  body[data-theme=dark] .dropdown-menu .dropdown-item {
    color: #8ea0af;
  }
  body[data-theme=dark] .dropdown-menu .dropdown-item:hover {
    color: #8ea0af;
    background: #1d1d42;
  }
  body[data-theme=dark] .page-header h3 {
    color: #97A2D2;
  }
  body[data-theme=dark] .search-set .search-input input[type=search] {
    border: 1px solid #8ea0af;
  }
  body[data-theme=dark] .search-set .search-input input[type=search]::placeholder {
    color: #8ea0af;
  }
  body[data-theme=dark] .form-group input[type=text], body[data-theme=dark] .form-group input[type=password],
  body[data-theme=dark] .select2-container--default .select2-selection--single,
  body[data-theme=dark] .select2-container--focus .select2-selection--single, body[data-theme=dark] .form-control, body[data-theme=dark] .form-select,
  body[data-theme=dark] .select2-container--default .select2-selection--multiple {
    background: #1d1d42 !important;
    color: #97A2D2;
  }
  body[data-theme=dark] .form-group input[type=text]::placeholder, body[data-theme=dark] .form-group input[type=password]::placeholder,
  body[data-theme=dark] .select2-container--default .select2-selection--single::placeholder,
  body[data-theme=dark] .select2-container--focus .select2-selection--single::placeholder, body[data-theme=dark] .form-control::placeholder, body[data-theme=dark] .form-select::placeholder,
  body[data-theme=dark] .select2-container--default .select2-selection--multiple::placeholder {
    color: #97A2D2;
  }
  body[data-theme=dark] .select2-container--default .select2-results__option[aria-selected=true] {
    background: #1d1d42 !important;
    color: #97A2D2 !important;
  }
  body[data-theme=dark] .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #97A2D2;
  }
  body[data-theme=dark] .custom-file-container .custom-file-container__custom-file__custom-file-control {
    background: #1d1d42;
    color: #97A2D2;
  }
  body[data-theme=dark] .input-group-text {
    background: #1d1d42;
    color: #97A2D2;
    border: 1.5px solid rgba(145, 158, 171, 0.32);
  }
  body[data-theme=dark] .image-upload:hover {
    background: #1d1d42;
  }
  body[data-theme=dark] .productdetails > ul > li h4 {
    color: #8ea0af;
  }
  body[data-theme=dark] .profile-set .profile-top .profile-contentname h2 {
    color: #97A2D2;
  }
  body[data-theme=dark] .total-order ul li h4 {
    background: #1d1d42;
  }
  body[data-theme=dark] .total-order ul li h5 {
    color: #97A2D2;
  }
  body[data-theme=dark] .product-details {
    background: #1d1d42;
  }
  body[data-theme=dark] .product-details h6 {
    color: #97A2D2;
  }
  body[data-theme=dark] .comp-section .card-header {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .comp-section .nav-tabs.nav-tabs-solid {
    background-color: #1d1d42;
  }
  body[data-theme=dark] .list-group-item {
    background: #1d1d42;
    color: #97A2D2;
  }
  body[data-theme=dark] .bg-white {
    background-color: #1d1d42 !important;
  }
  body[data-theme=dark] .section-header .section-title {
    color: #97A2D2;
  }
  body[data-theme=dark] .breadcrumb .breadcrumb-item a {
    color: #97A2D2;
  }
  body[data-theme=dark] .product-list ul li .productviews .productviewscontent .productviewsname h2, body[data-theme=dark] .product-list ul li .productviews .productviewscontent .productviewsname h3 {
    color: #97A2D2;
  }
  body[data-theme=dark] .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #97A2D2;
  }
  body[data-theme=dark] .alertify .ajs-header {
    background: #1d1d42;
  }
  body[data-theme=dark] .alertify .ajs-dialog {
    background: #1d1d42;
  }
  body[data-theme=dark] .alertify .ajs-footer {
    background: #1d1d42;
  }
  body[data-theme=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #97A2D2;
  }
  body[data-theme=dark] .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, body[data-theme=dark] .bs-popover-top > .popover-arrow::after {
    border-top-color: #353570;
  }
  body[data-theme=dark] .popover {
    background-color: #1d1d42;
    border: 1px solid #353570;
  }
  body[data-theme=dark] .popover .popover-header {
    background-color: #1d1d42;
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .popover .popover-body {
    color: #97A2D2;
  }
  body[data-theme=dark] .scroll-demo {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .timeline:before {
    background-color: #353570;
  }
  body[data-theme=dark] .timeline > li > .timeline-panel {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .timeline > li > .timeline-panel::before {
    border-top: 8px solid transparent;
    border-right: 0 solid #353570;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #353570;
  }
  body[data-theme=dark] .timeline > li > .timeline-panel::after {
    border-top: 7px solid transparent;
    border-right: 0 solid #353570;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #353570;
  }
  body[data-theme=dark] .timeline > li.timeline-inverted > .timeline-panel:after {
    border-right-width: 7px;
    border-left-width: 0;
  }
  body[data-theme=dark] .timeline > li.timeline-inverted > .timeline-panel::before {
    border-right-width: 8px;
    border-left-width: 0;
  }
  body[data-theme=dark] .twitter-bs-wizard .twitter-bs-wizard-nav .nav-link::after {
    background-color: #353570;
  }
  body[data-theme=dark] .btn-white {
    background-color: #141432;
    border: 1px solid #353570;
    color: #97A2D2;
  }
  body[data-theme=dark] .calendar-events:hover {
    background-color: #353570;
    border: 1px solid #353570;
    color: #97A2D2;
  }
  body[data-theme=dark] .fc-button {
    color: #97A2D2;
  }
  body[data-theme=dark] .sidebar.side-three.new-header .sidebar-menu > ul > li > a span {
    color: #8ea0af !important;
  }
  body[data-theme=dark] .sidebar.side-three.new-header .sidebar-menu > ul > li > a img, body[data-theme=dark] .sidebar.side-three.new-header .sidebar-menu > ul > li > a svg {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .cardhead .card-header {
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .sidebar-content {
    background: #1d1d42;
  }
  body[data-theme=dark] .sidebar-content .sidebar-header {
    border: 1px solid #353570;
  }
  body[data-theme=dark] .sidebar-content .sidebar-header h5 {
    color: #97A2D2;
  }
  body[data-theme=dark] .sidebar-content .sidebar-header a img {
    filter: brightness(0) saturate(100%) invert(65%) sepia(27%) saturate(206%) hue-rotate(165deg) brightness(93%) contrast(88%);
  }
  body[data-theme=dark] .sidebar-content .switch-wrapper {
    border-bottom: 1px solid #353570;
  }
  body[data-theme=dark] .sidebar-content .layout-wrap .status-text {
    color: #97A2D2;
  }
  body[data-theme=dark] .sidebar-content .layout-wrap .status-toggle .checktoggle {
    background: #97A2D2;
  }
  body[data-theme=dark] .login-logo.logo-normal {
    display: none;
  }
  body[data-theme=dark] .login-logo.logo-white {
    display: block !important;
  }
  body[data-theme=dark] .login-wrapper .login-content .login-userheading h3, body[data-theme=dark] .login-wrapper .login-content .login-userheading h4 {
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .form-login label {
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .signinform h4 {
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .signinform h4 a {
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .form-sociallink ul li a {
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .form-sociallink ul li a:hover {
    color: #fff;
  }
  body[data-theme=dark] .login-wrapper .login-content .form-login input {
    background: #1d1d42 !important;
    color: #97A2D2;
  }
  body[data-theme=dark] .login-wrapper .login-content .form-login input::placeholder {
    color: #97A2D2;
  }
  body[data-layout-mode=dark] .alertify .ajs-dialog {
    background-color: #333;
  }
  body[data-layout-mode=dark] .alertify .ajs-footer {
    color: #B8BCC9;
    background-color: #333;
    border-color: #333;
  }
  body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button {
    color: #B8BCC9;
  }
  body[data-layout-mode=dark] .alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: var(--background-primary-color);
  }
  body[data-layout-mode=dark] .alertify .ajs-header {
    color: #B8BCC9;
    background-color: #333;
    border-color: #333;
  }
  body[data-layout-mode=dark] .alertify .ajs-body {
    color: #B8BCC9;
  }
  body[data-layout-mode=dark] .alertify .ajs-body .ajs-content .ajs-input {
    color: #B8BCC9;
    background-color: #333;
    border: 1px solid #333;
  }
  body[data-layout-mode=dark] .alertify .ajs-content .ajs-input {
    display: block;
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cccccc;
    margin: 4px;
    padding: 8px;
  }
  
  /* custom css */

  /* Define light theme styles */
body.light-theme {
  --background: #ffffff;
  --black-white-text: #555;
  --text-color: #313131;
  --only-black: #000;
  --a-color: #313131;
  --border-color: #E8EBED;
  --table-background: #fff;
  --table-border: #f6f6f6;
  --pagination-disabled-icon: inherit;
  --pagination-icon: inherit;
  --btn-disabled-submit: #000;
  --btn-submit: #fff;
  --header-border: #DEE2E6;
  --user-name-color:#1B2950;
  --form-card-color:#f9f9f9;
  --scrollbar-bg-color:#dfdede;
  --input-field-disabled: #ededed;
  --h1-border-color: #c8c7c7;
  --table-image-contast: 1;
  background: #f6f4f4;
}

/* Define dark theme styles */
body.dark-theme {
  --background: #000;
  --text-color: #c9c9c9;
  --only-black: #000;
  --black-white-text: #c9c9c9;
  --a-color: #bdbdbd;
  --border-color: #404040;
  --table-background: #191919;
  --table-border: #3f3f3f;
  --pagination-disabled-icon: rgb(255 255 255 / 31%);
  --pagination-icon: rgb(255 255 255 / 64%);
  --btn-submit: #c9c9c9;
  --header-border: #000;
  --btn-disabled-submit: #c9c9c9;
  --user-name-color:#7c7c7c;
  --form-card-color:#000;
  --scrollbar-bg-color:#333232;
  --input-field-disabled: #c5c5c5;
  --h1-border-color: #535353;
  --table-image-contast: 0;
  background: var(--background);
}

  .sidebar{
    background: var(--background) !important;
    border-color: var(--border-color) !important;
  }
  .sidebar.dark .sidebar-menu > ul > li.submenu-open .submenu-hdr{
    color: var(--text-color);
  }
  .sidebar.dark .sidebar-menu > ul > li.submenu-open ul li a{
    color: var(--a-color);
  }
  .sidebar.dark .sidebar-menu > ul li.menu svg{
    fill: var(--a-color);
  }
  .sidebar.dark .sidebar-menu > ul li.menu svg .cls-1{
    /* fill: var(--a-color); */
    stroke: var(--a-color);
  }
  .order-data span.date-width img{
    filter: contrast(var(--table-image-contast));
  }
  .dark .card{
    background: var(--table-background);
    border-color: var(--background);
  }
  .dark .page-title h4, .dark .requiredfield h4,.dark .image-uploads h4,.dark .form-group h6{
    color: var(--text-color);;
  } 
  .dark .page-title h4 svg{
    stroke: var(--text-color);
  }
  .dark .page-title h6{
    color: var(--black-white-text);
  }
  .dark .form-group label{
    color: var(--text-color);;
  }
  .dark .image-upload:hover{
    background: var(--table-background);
  }
  input:disabled{
    background-color: var(--input-field-disabled);
  }
  .dark .card-body h1{
    border-color: var(--h1-border-color);
  }
  
.dark li.table-head{
  background: var(--background) !important;
}
.dark li.table-head span{
  color: var(--text-color) !important;
}
.dark .order-data li,.dark .order-data li > span{
  border-color: var(--table-border) !important;
}
.dark .order-data li > span{
  color: var(--text-color) !important;
}
.dark .mat-mdc-select-min-line{
  color: var(--text-color) !important;
}
.dark .mat-mdc-select-arrow svg{
  fill: var(--text-color);
}
.dark .mat-mdc-paginator-container{
  color: var(--text-color);
}
.dark .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon{
  fill: var(--pagination-disabled-icon) !important;
}
.dark .mat-mdc-paginator-icon{
  fill: var(--pagination-icon) !important;
}
.dark .btn-submit{
  color: var(--btn-submit) !important;
}
.dark .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper{
  background: var(--background);
}
.header {
  background: var(--table-background) !important;
  border-color: var(--header-border) !important;
}
.header .header-left{
  border-color: var(--header-border) !important;
}
.user-info .user-name{
  color: var(--user-name-color) !important;
}
.user-menu.nav > li > a.dropdown-toggle.nav-link.show{
  background: var(--table-background) !important;
}
.user-info::after{
  border-color: var(--text-color) !important;
}
.form-card{
  background: var(--form-card-color) !important;
}
.p-chips-multiple-container{
  background: var(--form-card-color) !important;
}
.table thead th{
  background: var(--background) !important;
  color: var(--text-color);
}
.table thead{
  border-color: var(--border-color);
}
.table tbody tr td{
  background: var(--table-background) !important;
  border-color: var(--border-color);
  color: var(--text-color);
}
/* ::-webkit-scrollbar-thumb{
  background-color: #505050 !important;
} */
::-webkit-scrollbar-track {
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg-color);
}
.sidebar::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg-color);;
}
.btn:disabled{
  color: var(--btn-disabled-submit) !important;
}
.mdc-text-field--filled.mdc-text-field--disabled{
  background-color: var(--background) !important;
}
/* ::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-bg-color);;
} */
.mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background{
  border-color: var(--black-white-text) !important;
}
.menu.active svg g{
  fill: var(--background-primary-color) !important;
  stroke: var(--background-primary-color) !important;
  }
.menu.active svg{
  fill: var(--background-primary-color) !important;
  }
.menu.active svg .cls-1{
  stroke: var(--background-primary-color) !important;
}
.ng-select.ng-select-disabled>.ng-select-container {
  background-color: var(--input-field-disabled) !important;
}