.p-button {
    color: #ffffff;
    background: var(--background-primary-color);
    border: 1px solid var(--background-primary-color);
    padding: 4px 10px;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px !important;
    margin: 0 5px !important;
    font-size: 14px !important;
}
.p-dropdown span.p-element.p-dropdown-label.p-inputtext.p-placeholder,
.p-dropdown .p-element.p-dropdown-label {
    padding: 5px 10px !important;
    font-size: 13px !important;
}
.p-toast .p-toast-message .p-toast-message-content {
    padding: 0.6rem;
    border-width: 0 0 0 6px;
}