/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "src/assets/css/primeNgStyle.css";
@import url(./assets/css/style.css);
.none{
    display: none !important;
}

:root{
    --background-primary-color: #973CB6;
    --background-primary-hover-color: #973CB614;
    --main-background-color: #f6f4f4;
    --sidebar-background-color: #ffffff;
    --main-text-color: #313131;
    --font-size: 13px;
  }

::placeholder ,.mat-mdc-select-placeholder{
    color: #637381;
    font-weight: 500;
    font-size: 14px;
  }
  
  ::-ms-input-placeholder ,.mat-mdc-select-placeholder{ /* Edge 12 -18 */
    color: #637381;
    font-weight: 500;
    font-size: 14px
  }
.ng-touched.ng-invalid {
    border-color: red !important;
}
.error-message{
    color: red;
    font-size: 12px;
    margin: 3px 0 0;
    display: inline-block;
}
input:disabled{
    cursor: no-drop;
}
html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
.custom-select-ui {
    /* background: #ccc; */
    display: block !important;
}
mat-form-field.mat-mdc-form-field.custom-select-ui.ng-tns-c1205077789-0.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill.mat-form-field-hide-placeholder.mat-primary.ng-star-inserted {}

.custom-select-ui .mat-mdc-form-field-subscript-wrapper {
    display: none;
}
.custom-select-ui .mat-mdc-form-field-infix {
    min-height: 10px;
}
.custom-select-ui .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
}
.custom-select-ui .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    /* padding-top: 4px;
    padding-bottom: 8px; */
    padding-top: 0px;
    padding-bottom: 0px;
}
.custom-select-ui.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(-8px);
    top: 0px;
    position: relative;
}
.custom-select-ui .mdc-line-ripple::before , .custom-select-ui .mdc-line-ripple::after{
    border-bottom-width: 0 !important;
}
.custom-select-ui .mat-mdc-text-field-wrapper {
    width: 100%;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}
.custom-select-ui .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: #fff;
}
.mdc-list-item , div.mat-mdc-select-panel.filter-option {
    overflow: visible !important;
    border-bottom: 1px solid #eeeeee;
}
.mat-mdc-option.mdc-list-item--disabled {
    cursor: default  !important;
    pointer-events: auto  !important;
}
.mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text{
    opacity: 1  !important;
}
.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive{
    cursor: pointer  !important;
}
.mat-mdc-select-min-line{
    font-size: 14px;
    color: #637381 !important;
}
.mdc-checkbox , .mdc-checkbox__native-control , .mat-mdc-checkbox-touch-target ,.mdc-checkbox__background{
    width: 15px !important;
    height: 15px !important;
}
.mat-mdc-checkbox{
 position: absolute;
 top: -5px;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
    border-color: var(--background-primary-color) !important;
    background-color: var(--background-primary-color) !important;
}
.cdk-overlay-pane{
    /* width: auto !important; */
}
.mat-mdc-option .mdc-list-item__primary-text{
    font-size: 14px !important;
}
.mat-mdc-option{
    min-height: 35px !important;
}
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic{
    stroke: var(--background-primary-color) !important;
}

.mat-custom-input .mat-mdc-text-field-wrapper {
    width: 100%;
    background: none !important;
    border: none !important;
    padding: 0 !important;
}
.mat-custom-input .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 180px;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 0px !important;
    min-height: auto;
}
.mat-custom-input .mdc-text-field--filled .mdc-line-ripple::before , .mdc-text-field--filled .mdc-line-ripple::after{
border: none !important;
}
.mat-custom-input.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay{
    opacity: 0;
}
.mat-custom-input .mat-mdc-form-field-hint-wrapper, .mat-custom-input .mat-mdc-form-field-error-wrapper{
    padding: 0 !important;
}
.mat-custom-input.mat-mdc-form-field{
    display: block !important;
}


.success-snackbar .mdc-snackbar__surface {
    background: #00c955 !important;
    border-radius: 4px !important;
}
.error-snackbar .mdc-snackbar__surface {
    background: #e9292a !important;
    /* background: var(--background-primary-color) !important; */
    border-radius: 4px !important;
    /* padding: 8px 16px !important; */

}
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
    height: 36px !important;
}
.success-snackbar .mdc-snackbar__surface .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: #fff !important;
    letter-spacing: .5px !important;
    font-size: 14px !important;
    text-shadow: none !important;
}
.back-arrow{
    position: relative;
    top: -2px;
}
mat-tooltip-component{
    zoom: 1.2 !important;
}
